import { Grid } from '@mui/material';
import MediaBlock from './MediaBlock';
import MediaDescription from './MediaDescription';
import ViewAssetActionBlock from './ViewAssetActionBlock';
import type { FC } from 'react';
import { AssetDetailsSkeleton } from './index';
import { Suspense } from 'react';
import { useAssetFilesSync } from '../api/getAssetFiles';
import { useParams } from 'react-router';
import { useSynchronousAsset } from '../api';
import useRestrictedRedirect from 'hooks/useRestrictedRedirect';
import type { AccessError } from 'types/shared';

const AssetDetails: FC = () => {
  const { assetId } = useParams();

  const {
    data: asset,
    error,
    isPending,
  } = useSynchronousAsset({
    id: Number(assetId),
  });

  if (error && (error as AccessError).cause.status === 404) {
    (error as AccessError).cause.error = 'Asset not found';
    throw error as Error;
  }

  useRestrictedRedirect(error as AccessError, 'asset');

  useAssetFilesSync({
    params: { id: Number(assetId) },
    config: { enabled: !!assetId },
  });

  if (isPending || !asset) return <AssetDetailsSkeleton />;

  return (
    <Suspense fallback={<AssetDetailsSkeleton />}>
      <Grid
        container
        sx={(t) => ({
          position: 'relative',
          flexDirection: 'row',
          [t.breakpoints.down('md')]: { flexDirection: 'column' },
        })}
      >
        <ViewAssetActionBlock />
        <Grid xs={12} md={6} item display="flex" justifyContent="center" alignItems="flex-start">
          <MediaBlock />
        </Grid>
        <Grid
          item
          container
          xs={12}
          md={6}
          sx={(t) => ({ [t.breakpoints.up('md')]: { pl: 3 }, mb: 7 })}
          direction="column"
        >
          <MediaDescription />
        </Grid>
      </Grid>
    </Suspense>
  );
};

export default AssetDetails;
